<template>
  <v-container>
    <v-dialog v-model="show" max-width="500px">
      <div id="app">
        <DaumPostcode :on-complete="handleAddress" />
      </div>
      <v-text-field
        id="post1"
        v-model="post11"
        label="우편번호"
        prepend-icon="mdi-email"
      />
      <v-btn class="mt-8" color="primary" block @click="onSubmit">
        인증 요청
      </v-btn>
    </v-dialog>
  </v-container>
</template>

<script>
import DaumPostcode from "vuejs-daum-postcode";
import EventBus from "@/components/EventBus";

var handleAddress1 = (data) => {
  let fullAddress = data.address;
  let extraAddress = "";
  if (data.addressType === "R") {
    if (data.bname !== "") {
      extraAddress += data.bname;
    }
    if (data.buildingName !== "") {
      extraAddress +=
        extraAddress !== "" ? `, ${data.buildingName}` : data.buildingName;
    }
    fullAddress += extraAddress !== "" ? ` (${extraAddress})` : "";
  }
  console.log(fullAddress); // e.g. '서울 성동구 왕십리로2길 20 (성수동1가)
};

export default {
  name: "App",
  components: {
    DaumPostcode,
  },
  data() {
    return {
      valid: true,
      post11: "",
      show: false,
    };
  },
  computed: {
    editItem() {
      console.log(this.handleAddress);
      return this.handleAddress;
    },
  },
  created() {
    EventBus.$on("push-post", (payload) => {
      this.show = true;
      console.log(payload);
    });
  },
  methods: {
    handleAddress1,
    handleAddress(data) {
      console.log(data);
      this.post11 = data.address;
      EventBus.$emit("push-post-ret", data);
      this.show = false;
    },
    onSubmit() {
      console.log(this.handleAddress);
    },
  },
};
</script>
